import loadable from "@loadable/component";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

const Login = loadable(() => import("../pages/login"));
const Layout = loadable(() => import("../layout/BasicLayout"));
const Dashboard = loadable(() => import("../pages/dashboard"));
const Clients = loadable(() => import("../pages/clients"));
const Ventures = loadable(() => import("../pages/ventures"));
const Leads = loadable(() => import("../pages/leads"));
const Users = loadable(() => import("../pages/users"));

function RoutesContainer() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Layout />}>
          <Route index element={<Navigate to="dashboard" />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="clients" element={<Clients />} />
          <Route path="ventures" element={<Ventures />} />
          <Route path="leads" element={<Leads />} />
          <Route path="users" element={<Users />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default RoutesContainer;
